import resolveImage from "./resolveImage";

const resolveArticle = article => {
  const { descriptor, image, metaImage, topics, metaDescription } = article;

  return {
    ...article,
    descriptor: descriptor || metaDescription,
    image: resolveImage(image || metaImage),
    topics: topics?.map(t => t.uid),
    type: article.type || "article",
  };
};

export default resolveArticle;
