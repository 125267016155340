import React from "react";
import loadable from "@loadable/component";

const PageContainer = loadable(() => import("@templates/DefaultPageContainer"));
const Hero = loadable(() => import("@organisms/Hero"));
const ArticleList = loadable(() => import("@organisms/ArticleList"));

const PagePage = ({ data }) => {
  const { uid, meta, hero, articles } = data;
  return (
    <PageContainer meta={meta} path={uid}>
      <Hero {...hero} />
      <ArticleList items={articles} />
    </PageContainer>
  );
};

export default PagePage;
